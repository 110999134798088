<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>提货查询</p>
      </div>
    </div>
    <el-table :data="tableData" border>
      <el-table-column type="index" label="序号" width="70"></el-table-column>
      <el-table-column prop="pickNo" label="提货码"></el-table-column>
      <el-table-column prop="name" label="商品"></el-table-column>
      <el-table-column prop="quantity" label="提货数量"></el-table-column>
      <el-table-column prop="tradePrice" label="交易金额"></el-table-column>
      <el-table-column prop="warehouse" label="目的的仓库"></el-table-column>
      <el-table-column prop="value" label="状态"></el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        // {
        //   pickNo: "c143f70f366544ad9aa0cb76f9957ec6",
        //   name: "钢材",
        //   quantity: 2,
        //   tradePrice: "300.00 RMB",
        //   warehouse: "天津仓库",
        //   value: "已申请"
        // },
        // {
        //   pickNo: "c143f70f366544ad9aa0cb76f9957ec6",
        //   name: "钢材",
        //   quantity: 2,
        //   tradePrice: "300.00 RMB",
        //   warehouse: "天津仓库",
        //   value: "已申请"
        // },
        // {
        //   pickNo: "c143f70f366544ad9aa0cb76f9957ec6",
        //   name: "钢材",
        //   quantity: 2,
        //   tradePrice: "300.00 RMB",
        //   warehouse: "天津仓库",
        //   value: "已申请"
        // }
      ],
      current: 1,
      pageSize: 10,
      totalPage: null
    };
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryTakeGoodsOrder.param.page = this.current - 1;
      protocolFwd.param_queryTakeGoodsOrder.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryTakeGoodsOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .searchList {
  margin-bottom: 10px;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
